<template>
  <div class="bigpie">
    <div class="pie">
    <i-circle :percent="100*data.count/data.total" dashboard :size="130" :stroke-width="15" :stroke-color="background" :trail-width="15" trail-color="#8DD4FF">
      <span class="demo-circle-inner" style="font-size:24px">{{data.count}}</span>
    </i-circle>
    </div>
      <div class="pie-data">
        <div class="pie-data-left">0</div>
        <div class="pie-data-content">{{data.name}}</div>
        <div class="pie-data-right">{{data.total}}</div>
      </div> 

  </div>
</template>

<script>
export default {
  props:{
      background:{type: String,default:"#BC0000"},
      data:{type:Object,default:()=>{
        return {total:0,count:0,name:''}
        }},
  },
  data() {
    return {

    }
  },
  methods: {
  
  },
  created() {
 
  },
};
</script>

<style lang='less'>
.bigpie{
  flex-direction: column;
  align-items: center;
  justify-content:center;
    .pie{
      width: 130px;
      height:120px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .pie-data{
      display: flex;
      width: 100px;
      margin-left:10px;
      justify-content:space-between;
      .pie-data-left{
        text-align: left;
        font-size: 14px;
      }
      .pie-data-content{
        text-align: center;
        font-size: 20px;
        color:#708ACC;
      }
      .pie-data-right{
        text-align: right;
        font-size: 14px;
      }
    }
}
</style>
