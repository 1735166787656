<template>
  <div class="bigcard">
    <div class="header"><div class="header-left"></div><div  class="header-middle"></div><div  class="header-right"></div></div>
    <div  class="content"><div class="cont"><slot></slot></div></div>
    <div  class="footer"><div class="footer-left"></div><div  class="footer-middle"></div><div  class="footer-right"></div></div>
  </div>
</template>

<script>
export default {
  
  data() {
    return {

    }
  },
  methods: {
  
  },
  created() {
 
  },
};
</script>

<style lang='less'>
.bigcard{
  overflow: hidden;
  .header{
    width:100%;
    overflow: hidden;
    .header-left{
      float: left;
      width:20px;
      height:20px;
      border-top: 2px solid #0EFCFF;
      border-left: 2px solid #0EFCFF;
    }
    .header-middle{
      float: left;
      width:calc(100% - 40px);
      height:20px;
      border-top: 2px solid #6076AD99;
    }
    .header-right{
      float: right;
      width:20px;
      height:20px;
      border-right: 2px solid #0EFCFF;
      border-top: 2px solid #0EFCFF;
    }
  }
  .content{
    width:100%;
    overflow: hidden;
    height:calc(100% - 40px);
    border-right: 2px solid #6076AD99;
    border-left: 2px solid #6076AD99;
    .cont{
      padding:0 20px;
    }
  }
   .footer{
    width:100%;
    overflow: hidden;
    .footer-left{
      float: left;
      width:20px;
      height:20px;
      border-bottom: 2px solid #0EFCFF;
      border-left: 2px solid #0EFCFF;
    }
    .footer-middle{
      float: left;
      width:calc(100% - 40px);
      height:20px;
      border-bottom: 2px solid #6076AD99;
    }
    .footer-right{
      float: right;
      width:20px;
      height:20px;
      border-right: 2px solid #0EFCFF;
      border-bottom: 2px solid #0EFCFF;
    }
  }
}
</style>
