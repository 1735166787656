<template>
  <div class="forecastitem" @click="goto">
  <div v-if="data.evn=='预警'" class="forecast-alarm">
    <div class="user-logo">
      <img :src="data.logo"/>
      <div>{{data.sex}}</div>
      <div>{{data.age}}</div>
      </div>
      <div class="user-line"></div>
     <div class="user-content">
      <div class="name">{{data.username}}</div>
      <div class="alarm-info" v-for="(item,i) in data.alarms" :key="'al'+i">{{item}}</div>
       </div>
  </div>
  <div v-else-if="data.evn=='处理中'"  class="forecast-alarm2">
    <div class="user-logo">
      <img :src="data.logo"/>
      <div>{{data.username}}</div>
    </div>
    <div class="user-content">
      <div>{{data.sex}}</div>
      <div>{{data.age}}</div>
    </div>
  </div>
  <div v-else  class="forecast-alarm2 forecast-alarm3">
      <div class="user-logo">
      <img :src="data.logo"/>
      <div>{{data.username}}</div>
    </div>
    <div class="user-content">
      <div>{{data.sex}}</div>
      <div>{{data.age}}</div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props:{
    data:{type:Object,default:()=>{
      return {evn:'预警'};
    }}
  },
  data() {
    return {

    }
  },
  methods: {
  goto(){
    this.$emit("click");
  }
  },
  created() {
 
  },
};
</script>

<style lang='less'>
.forecastitem{
  float:left;
  .forecast-alarm{
    width:380px;
    height:220px;
    margin:10px;
    border-radius: 12px;
    background: #BC0000;
    display:flex;
    .user-logo{
      font-size: 28px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin:10px;
      font-weight: lighter;
      img{
        width:60px;
        height:60px;
        margin:10px 0;
      }
      div{
        margin:10px 0;
      }
    }
    .user-content{
      display:flex;
      flex-direction: column;
      margin:10px;
      font-size: 28px;
      font-weight: lighter;
      .name{
        font-size: 36px;
        color:#EEFF00;
        font-weight: normal;
      }
      .alarm-info::before{
        content:"@ ";
        
      }
    }
    .user-line{
      width:1px;
      height:160px;
      margin:30px 5px;
      background: #EEEEEE;
    }
  }
  .forecast-alarm2{
    width:180px;
    height:100px;
    margin:10px;
    border-radius: 12px;
    background: #E2AA03;
    .user-logo{
      font-size: 28px;
      display:flex;
      margin:5px;
      font-weight: lighter;
      align-items: center;
      img{
        width:45px;
        height:45px;
        margin:5px;
      }
      div{
        margin:0 5px;
        white-space:nowrap;
      }
    }
    .user-content{
      font-size: 25px;
      display:flex;
      font-weight: lighter;
      justify-content: space-between;
      align-items: center;
      background: #FCE5A8;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      div{
        margin:0 5px;
        white-space:nowrap;
        color:#333;
      }
    }
  }
   .forecast-alarm3{
    background: #0085D0;
    .user-content{
      background: #8DD4FF;
    }
  }
}
</style>
