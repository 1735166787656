<template>
  <div class="bg">
    <div class="title">列丰智慧家庭预警监测平台</div>
    <div class="card-title card1-title">实时监测</div>
    <Card class="card1">
      <div class="pie-card">
      <Pie :data="{total:20,count:10,name:'预警'}"></Pie>
       <Pie  background="#FEC008" :data="{total:10,count:6,name:'处理中'}"></Pie>
      </div>
    </Card>
    <div class="card-title card2-title">监测事件</div>
     <Card class="card2">
      <div class="event-item" v-for="(item,i) in forecastEvent" :key="'fe'+i">
        <div class="event-item-event" :style="'background:'+(item.evn=='预警'?'#BC0000':'#FEC008')+';'">{{item.evn}}</div>
        <div class="event-item-content">
          <div class="event-item-content-user">
            <div>{{item.username}}</div>
            <div>{{item.alarm}}</div>
            <div>{{item.time}}</div>
          </div>
          <div>
            {{item.adrss}}
          </div>
        </div>
      </div>
       
    </Card>
    <div class="card3-title">
      <div class="forecast-1"></div><div>预警</div><div class="forecast-2"></div><div>处理中</div>
    </div>
     <Card class="card3">
       <div class="forecast-item">
      <Forecastitem v-for="(item,i) in forecastList" :key="'fel'+i" :data="item" @click="goto"></Forecastitem>
       </div>
    </Card>
  </div>
</template>

<script>
//@route('/bigscreenindex')
import Forecastitem from "./childrens/forecastitem";
import Card from "./childrens/card";
import Pie from "./childrens/pie";
export default {
  components: {
    Card,Pie,Forecastitem
  },
  
  data() {
    return {
      forecastEvent:[
        {
          evn:'预警',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房',
          logo:'/bigscreen/user.png',
        },
       {
          evn:'预警',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },
       {
          evn:'处理中',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },
       {
          evn:'处理中',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },
       {
          evn:'处理中',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },
       {
          evn:'处理中',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },
       {
          evn:'处理中',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },       {
          evn:'处理中',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },       {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },      {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },       {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },      {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },       {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },      {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },       {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },      {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },       {
          evn:'正常',
          username:'赤霞珠',
          alarm:'收到援助呼叫',
          time:'2021-05-13  6:34:12',
          adrss:'广州市番禺区小谷围街道星汇社区创业小区A栋1004房'
        },
      ],
      forecastList:[
        {
          evn:'预警',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:['烟感异常','睡眠带离线','收到援助呼叫'],
        },
        {
          evn:'预警',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:['收到援助呼叫','收到援助呼叫','收到援助呼叫'],
        },
        {
          evn:'处理中',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:['收到援助呼叫','收到援助呼叫','收到援助呼叫'],
        },
        {
          evn:'处理中',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:['收到援助呼叫','收到援助呼叫','收到援助呼叫'],
        },
        {
          evn:'处理中',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:['收到援助呼叫','收到援助呼叫','收到援助呼叫'],
        },
        {
          evn:'处理中',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:['收到援助呼叫','收到援助呼叫','收到援助呼叫'],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
         {
          evn:'正常',
          logo:'/bigscreen/user.png',
          username:'赤霞珠',
          sex:'男',
          age:'64岁',
          alarms:[],
        },
      ],
    }
  },
  methods: {
    goto(){
      location.href="/bigscreen.html";
    }
  },
  created() {
 
  },
};
</script>

<style lang='less'>
body{
  overflow: hidden;
}
.bg{
    width:1920px;
    height:1080px;
    background-image: url(/bigscreen/index_bg.png);
    background-size:100% 100%;
    border:0px;
    overflow: hidden;
    margin-top:calc((100vh - 1080px)/2);
    margin-left: calc((100vw - 1920px)/2);
    color:#fff;
    font-size:32px;

    .title{
      position: fixed;
      top:0px;
      text-align: center;
      font-size:48px;
      letter-spacing: 5px;
      line-height: 110px;
      font-weight:bold;
      color: #8D8D8D;
      left:calc((100% - 1599px)/2);
      width:1599px;
      height:130px;
      background-image: url(/bigscreen/index_title_bg.png);
      background-size:100% 100%;
    }
    .card-title{
      position: fixed;
      width:298px;
      height:42px;
      padding-left:25px;
      font-size: 20px;
      line-height: 42px;
      letter-spacing: 3px;
      color: #0EFCFF;
      font-weight: bold;
      background-image: url(/bigscreen/index_item_bg.png);
      background-size:100% 100%;
    }
    .card1-title{
      top:122px;
      left:34px;
    }
    .card2-title{
      top:392px;
      left:34px;
    }
     .card3-title{
      display: flex;
      align-items:center;
      font-size: 30px;
      line-height: 42px;
      letter-spacing: 3px;
      color: #0EFCFF;
      font-weight: bold;
      position: fixed;
      top:124px;
      right:34px;
      .forecast-1{
        width:36px;
        height:36px;
        background: #BC0000;
        margin:0 10px;
        border-radius: 5px;
      }
      .forecast-2{
        width:36px;
        height:36px;
        background: #E2AA03;
        margin:0 10px;
        border-radius: 5px;
      }
    }
    .card1{
      position: fixed;
      top:172px;
      left:34px;
      width:570px;
      height:190px;
      background: #0A142C99;
    }
     .card2{
      position: fixed;
      top:442px;
      left:34px;
      width:570px;
      height:612px;
      background: #0A142C99;
    
    }
    .event-item{
        display: flex;
        margin:20px 0;
        .event-item-event{
          width:60px;
          height:60px;
          border-radius: 60px;
          background: #BC0000;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .event-item-content{
          margin-left:20px;
          color: #65E0E3;
          font-size: 18px;
           display: flex;
           flex-direction: column;
           .event-item-content-user{
             display: flex;
             justify-content: space-between;
           }
        
        }
      }
     .card3{
      position: fixed;
      top:172px;
      right:34px;
      width:1258px;
      height:883px;
      background: #0A142C99;
    }
    .pie-card{
       display: flex;
       width:100%;
        justify-content:space-around;
    }

    .forecast-item{
      // display: flex;
      // align-items: flex-start;
      // flex-wrap: wrap;
      width:100%;
    }
  }
</style>
